export default function getCode(){
    return [
`// Receives a CSS selector, as does (document.querySelector).

/* Instantiate an object of type (CosmicAnimation), receives by parameters: 
    --- The selector of our element.
    --- The name of our animation. */
const cosmicDiv = new CosmicAnimation('#idDiv', 'div-animation');
`,
`/* Returns CosmicAnimation[]: 
    --- Selectors.
    --- Names for each animation. */
const arrElementsCosmic = new CosmicAnimation(
    ['#idDiv', '.div', 'div'], 
    ['animacion-1', 'animacion-2', 'animacion-3']
);

arrElementsCosmic[0]; // CosmicAnimation
`,
`const arrElementsCosmic = new CosmicAnimation(
    ['#idDiv', '.div', 'div'], 
    ['animacion-1', 'animacion-2']
);
`,
`arrElementsCosmic.forEach(elementCosmic => {
    // ...
});
`,
`const cosmicDiv = new CosmicAnimation('#idDiv');

// Settings for this element.
cosmicDiv.animation = {
    // Animation name for this element.
    name: 'name',
    // Animation time.
    delay: 0,
    // Duration time.
    duration: 1500,
    // How many times the animation will be repeated.
    iterationCount: 1, 
    // Direction of the animation, (normal, reverse, alternate, etc).
    direction: 'normal', 
    // Type of functionality, (smooth, fast, same, etc).
    timingFunction: 'linear',
    // Save final styles, (save changes or not).
    fillMode: 'forwards'
};
`,
`cosmicDiv.observeViewport = {
    // Defines if the object will take the animation only when it is inside the viewport, that is, the visible part of the user's page.
    enabled: true,
    // Defines whether the animation will be repeated only once or indefinitely.
    infinite: true
};
`,
`cosmicDiv
    .width(0, 200).height(0, 200)
    .color('red', 'blue').bgColor('yellow', 'green')
    .translateX(300, 0).translateY(300, 0).scale(0, 1)
    .padding(0, 30).margin(0, 30)
    // Run the animation.
    .execute();
`,
`// Property, (start, end).
cosmicDiv.addProperty(
    'border: 2px solid #000;', 
    'border: 6px solid blue;'
);
`,
`@keyframes animation-name{
    from{
        border: 2px solid #000;
    }
    to{
        border: 6px solid blue;
    }
}
`,
`@keyframes circle-move{
    /* 1/3 */
    0%{ background-color: blue; opacity: 0; }
    /* 2/3 */
    50%{ background-color: blue; opacity: 0.5; }
    /* 3/3 */
    100%{ background-color: red; opacity: 1; }
}
`,
`cosmicDiv.animation = {
    name: 'circle-move',
    delay: 0,
    duration: 3000,
    iterationCount: 'infinite',
    direction: 'alternate',
    timingFunction: 'linear',
    fillMode: 'forwards'
};
`,
`cosmicDiv
    // We define each state of each property at each stage of the animation.
    .opacity(0, "1/3").opacity(0.5, "2/3").opacity(1, "3/3")
    .bgColor("blue", "1/3").bgColor("blue", "1/2").bgColor("red", "3/3")
    // We run the animation.
    .execute();
`,
`cosmicDiv
    .opacity(0, "1/3").opacity(0.5, "2/3").opacity(1, "3/3")
    // Same logic with Arrays.
    .bgColor("blue", ["1/3", "2/3"])
    .bgColor("red", "3/3")
    // We run the animation.
    .execute();
`,
`@keyframes animation-name{
    0%{ transform: scale(0); background-color: red;}

    25%{ transform: scale(0.3); background-color: blue;}

    50%{ transform: scale(0.3); background-color: blue;}

    75%{ transform: scale(0.4); background-color: blue;}

    100%{ transform: scale(0.5); background-color: red;}
}
`,
`// Some settings.
cosmicDiv.animation.name = "AnimacionPulsacion";
cosmicDiv.animation.duration = 3000;
cosmicDiv.animation.iterationCount = Infinity;

// Opacity at different points in the animation.
cosmicDiv.scale(0, '1/5')
    .scale(0.3, '2/5').scale(0.3, '3/5')
    .scale(0.4, '4/5').scale(0.5, '5/5');

// Now the background color.
cosmicDiv.bgColor('red', '1/5')
    .bgColor('blue', '2/5')
    .bgColor('blue', '3/5')
    .bgColor('blue', '4/5')
    .bgColor('red', '5/5')
    .execute();
`,
`cosmicDiv.scale(0, '1/5')
    // The state of the property is the same at these points in the animation.
    .scale(0.3, ['2/5', '3/5'])
    .scale(0.6, '4/5')
    .scale(1, '5/5')
    // Others.
    .bgColor('red', ['1/5', '5/5'])
    .bgColor('blue', ['2/5', '3/5', '4/5'])
    .execute();
`,
`@keyframes circle-move{
    0%{ transform: translateX(0px); opacity: 0; }

    12.5%{ transform: translateX(0px); opacity: 0; }

    25%{ transform: translateX(100px); opacity: 0.3; }

    37.5%{ transform: translateX(100px); opacity: 0.3; }

    50%{ transform: translateX(200px); opacity: 0.6; }

    62.5%{ transform: translateX(200px); opacity: 0.6; }

    75%{ transform: translateX(300px); opacity: 0.8; }

    87.5%{ transform: translateX(400px); opacity: 1; }

    100%{ transform: translateX(400px); opacity: 1; }
}
`,
`// Every two stages the animations are the same.
cosmicDiv
    .opacity(0, ['1/9', '2/9'])
    .opacity(0.3, ['3/9', '4/9'])
    .opacity(0.6, ['5/9', '6/9'])
    // Here it is different.
    .opacity(0.8, '7/9')
    .opacity(1, ['8/9', '9/9']);

// See that sometimes it can be neater without it (chaining of methods).
// But it's your choice.
cosmicDiv.translateX(100, ['1/9', '2/9', '3/9']);
cosmicDiv.translateX(200, ['4/9', '5/9', '6/9']);
cosmicDiv.translateX(300, ['7/9', '8/9', '9/9']);

cosmicDic.execute();
`,
`/**
 * From.
*/

// Appears to the right from off screen.
cosmicDiv.fromWindowTo("right", 2000, 0);

// Appears to the left from off screen.
cosmicDiv.fromWindowTo("left", 2000, 0);

// A small circle comes from above and leaves a square.
cosmicDiv.circleTo('top', 2500, 3000);

// A small circle comes from below and leaves a square.
cosmicDiv.circleTo('bottom', 2500, 3000);

/**
 * Appear.
*/

// It only appears from a broadcast.
cosmicDiv.appear(1300, 0);

// Appears from a blur towards the specified direction.
cosmicDiv.appearTo("top", 1300, 0);
cosmicDiv.appearTo("right", 1300, 0);
cosmicDiv.appearTo("bottom", 1300, 0);
cosmicDiv.appearTo("left", 1300, 0);

/**
 * Fade out.
*/

// It just disappears.
cosmicDiv.fadeOut(1300, 0);

// Disappears into a blur in the specified direction.
cosmicDiv.fadeOutTo("top", 1300, 0);
cosmicDiv.fadeOutTo("right", 1300, 0);
cosmicDiv.fadeOutTo("bottom", 1300, 0);
cosmicDiv.fadeOutTo("left", 1300, 0);

// The element appears from top to bottom, and after a moment it is hidden again.
cosmicDiv.appearAndFadeOut(1300, 0);

/**
 * Fade out by letters.
*/

/* The letters of a text disappear one by one, receive by parameters: 
    --- Pixel spacing of text spaces.
    --- Time in which each letter disappears. */
cosmicDiv.fadeOutLetters(6, 100);

// The text of the element disappears letter by letter towards a specified direction.
cosmicDiv.fadeOutLettersTo("top", 6, 100);
cosmicDiv.fadeOutLettersTo("right", 6, 100);
cosmicDiv.fadeOutLettersTo("bottom", 6, 100);
cosmicDiv.fadeOutLettersTo("left", 6, 100);

// The text disappears letter by letter to random addresses: ["top", "right", "bottom", "left", "center"].
cosmicDiv.fadeOutLettersRandom(6, 100);

/**
 * Scale.
*/

// Increase from a minimum scale to its normal scale.
cosmicDiv.increment(2000, 0);

// It increases from a minimum scale to its normal scale and at the end gives a pulse.
cosmicDiv.incrementPulse(2000, 0);

// Give a pulse.
cosmicDiv.pulse(1000, 0);

// Alpita indefinitely increases and decreases its scale slightly.
cosmicDiv.palpite(3000, 0);
`,
`// The time will be (x2) since the animation is repeated twice.
cosmicDiv.animation.iterationCount = 2;

// We use a prefabricated animation.
cosmicDiv.spiralTo("right");

// We run the animation.
cosmicDiv.execute();

/* The (terminate) method is executed only when the animation ends, receive by parameter: 
    --- A callback that is executed when the animation ends. */
cosmicDiv.terminate(function(){
    console.info("The animation is over");
});
`,
`const cosmicDiv = new CosmicAnimation(".my-element");
cosmicDiv.animation.iterationCount = 2;
cosmicDiv.appearTo("right");
cosmicDiv.execute();

// When the animation ends.
cosmicDiv.terminate(function(){
    // We clean up the element animations.
    cosmicDiv.deleteAnimation();
    // New animations and we run.
    cosmicDiv.appearTo("left");
    cosmicDiv.execute();
});
`,
`const cosmicDiv = new CosmicAnimation("#idOne");

// First animations.
cosmicDiv.animation.iterationCount = 2;
cosmicDiv.spiralTo("right");
cosmicDiv.execute();

// At the end of the animation.
cosmicDiv.terminate(function(){
    
    // Second animation.
    cosmicDiv.deleteAnimation();
    cosmicDiv.spiralTo("left");
    cosmicDiv.execute();

    cosmicDiv.terminate(() => {
        // Third animation.
        cosmicDiv.deleteAnimation();
        cosmicDiv.increment();
        cosmicDiv.execute();

        cosmicDiv.terminate(() => {
            // Fourth animation.
            cosmicDiv.deleteAnimation();
            cosmicDiv.incrementPulse();
            cosmicDiv.execute();

            cosmicDiv.terminate(function(){
                console.info("The last animation is cleared.");
            });		
        });
    });
});
`
    ]
}