import React, { Component } from "react";

import Code from "../components/code";

import CosmicAnimation from "cosmic-animation";

import "../styles/css/main.css";

class Main extends Component{

    componentDidMount(){
        const h1 = new CosmicAnimation(".presentation h1"), 
              a = new CosmicAnimation(".presentation a");

        h1.appearTo("bottom", 800).execute();
        a.appearTo("top", 800).execute();
    }

    render(){
        return (
            <main>
                <article className="presentation padding">
                    <figure></figure>
                    <figure></figure>
                    <div>
                        <h1>Create quick animations using <span>CosmicAnimation</span></h1>
                    </div>
                    <div>
                        <a href="#idGetStarted">Get Started</a>
                    </div>
                </article>
                <div className="padding code-main">
                    <h3>What is <b>CosmicAnimation</b>?</h3>
                    <p>In the vast and dynamic world of web development, the ability to create captivating visual experiences is essential. One of the most powerful tools to achieve this is the use of JavaScript animation libraries. These libraries not only simplify the animation process, but also open up a range of creative possibilities for developers. Explore some of the features that make <b>CosmicAnimation</b> so easy to create dynamic animations.</p>
                    <h3 id="idGetStarted"><b>NPM</b> installation</h3>
                    <p>Place the following command in the console and install it in your project easily.</p>
                    <Code type="batch" value="npm install cosmic-animation"/>
                    <p>Import the library using CosmicAnimation.</p>
                    <Code type="js" value="import CosmicAnimation from 'cosmic-animation';"/>
                </div>
            </main>
        );
    }
}

export default Main;