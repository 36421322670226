import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Componentes.
import HeaderComponent from "./components/header";
import RouteProvider from "./components/routes";
import Footer from "./components/footer";

// Paginas.
import Main from "./pages/main";
import Docs from "./pages/docs";
import Contact from "./pages/contact";
import Error404 from "./pages/404";

// CSS.
import "./styles/css/index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <HeaderComponent/>
      <Routes>
        <Route path="/" element={<Main />}/>
        <Route path="/docs" element={<Docs />}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="*" element={ <Error404/> }/>
      </Routes>
      <Footer/>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();