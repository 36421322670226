import React, { Component } from "react";

import "../styles/css/footer.css";

// Iconos.
import IconGit from "../icons/git";
import IconEmail from "../icons/email";
import IconWorld from "../icons/world";
import IconLinkedin from "../icons/linkedin";
import IconNPM from "../icons/npm";

export default class Footer extends Component{
    render(){
        return (
            <footer className="footer">
                <div className="padding">
                    <a target="_blank" href="mailto:contact@brandondeveloper.com" title="contact@brandondeveloper.com">
                        <IconEmail/>
                    </a>
                    <a target="_blank" href="https://www.linkedin.com/in/brandon-anthony-olivares-amador-6a86ab298/" title="Brandon Anthony Olivares Amador">
                        <IconLinkedin/>
                    </a>
                    <a target="_blank" href="https://github.com/cosmic-dev-code" title="Cosmic Web">
                        <IconGit/>
                    </a>
                    <a target="_blank" href="https://webdev.brandondeveloper.com/" title="Brandon Developer">
                        <IconWorld/>
                    </a>
                    <a target="_blank" href="https://www.npmjs.com/package/cosmic-animation" title="Brandon Developer">
                        <IconNPM/>
                    </a>
                </div>
                <small className="padding">
                    <span>Derechos reservados</span>
                    <span>CosmicWeb</span>
                </small>
            </footer>
        );
    }
}