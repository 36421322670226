import React, { Component } from "react";
import { createRoot } from "react-dom/client";

import Console from "../components/console.jsx";
import Code from "../components/code";
import getCode from "./data.js";

import CosmicAnimation from "cosmic-animation";

import "../styles/css/docs.css";

export default class Docs extends Component{
    componentDidMount(){
        // Animacion del titulo.
        let h1 = new CosmicAnimation("h1")
        h1.animation.duration = 500;
        h1.scale(0, 1,).execute();

        const ulIndices = document.querySelector(".aside-index > ul"), 
              footer = document.querySelector(".footer > div");

        window.addEventListener("scroll", event => {
            if(window.scrollY > 500 && footer.getBoundingClientRect().top > 700) ulIndices.classList.add("float");
            else ulIndices.classList.remove("float");
        });

        /**
         * Armar el indice de forma dinamica.
         */

        let indices = [].map.call(document.querySelectorAll("h3"), h3 => {
            return new Object({
                title: h3.textContent, 
                path: h3.id
            });
        });

        createRoot(ulIndices).render(
            <>
                {
                    indices.map(index => <li>
                        <a href={ "#" + index.path }>{ index.title }</a>
                    </li>)
                }
            </>
        )
    }

    render(){
        return (
            <main>
                <article className="padding guide-start">
                    <h1>Usage guide</h1>
                </article>
                <div className="grid-container padding">
                    <div>
                        <article className="code">
                            <h3 id="idImport">Import <b>CosmicAnimation</b></h3>
                            <p>Use the NPM package manager to install the <b>CosmicAnimation</b> library.</p>
                            <Code type="sh" value="npm install cosmic-animation"/>
                            <p>Import the library using CosmicAnimation.</p>
                            <Code type="js" value="import CosmicAnimation from 'cosmic-animation';"/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idInstances">Instances</h3>
                            <p>Assuming you have an element you want to animate.</p>
                            <Code type="html" value='<div id="idDiv"></div>'/>
                            <p>You can instantiate a <b>CosmicAnimation</b> class using the following instruction.</p>
                            <Code type="js" value={ getCode()[0] }/>
                            <p>If you do not define the second parameter, a random animation name is assigned.</p>
                            <Code type="js" value=" const cosmicDiv = new CosmicAnimation('#idDiv');"/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idMoreElements">More elements</h3>
                            <p>Now you have more elements.</p>
                            <Code type="html" value=' <div></div>
                                <div id="idDiv"></div>
                                <div id="div"></div>'/>
                            <p>You can define more than one <b>CosmicAnimation</b> object at a time.</p>
                            <Code type="js" value={ getCode()[1] }/>
                            <p>The third element will have an automatic animation name.</p>
                            <Code type="js" value={ getCode()[2] }/>
                            <p>Animation names are assigned automatically.</p>
                            <Code type="js" value="const arrElementsCosmic = new CosmicAnimation(['#idDiv', '.div', 'div']);"/>
                            <p>Like any array, the returned array <b>CosmicAnimation[]</b> can be iterated.</p>
                            <Code type="js" value={ getCode()[3] }/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idSettings">Animation settings</h3>
                            <p>Like any CSS animation, if you already know the common CSS animation settings, you will see that it is the same.</p>
                            <p>These are the default settings.</p>
                            <Code type="js" value={ getCode()[4] }/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idObserverViewport">ObserverViewport</h3>
                            <p>We can configure the <b>ObserveViewport</b> to determine whether the animation is executed when it enters or exits the ObserverViewport.</p>
                            <p>The <b>ObserveViewport</b> object has two properties.</p>
                            <Code type="js" value={ getCode()[5] }/>
                            <Console type="observeViewport"/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idAnimationsStartEnd">Create (start) and (end) animations</h3>
                            <p>The methods receive two parameters: </p>
                            <p>
                                --- The value with which to start the element in the animation. 
                            </p>
                            <p>
                                --- The value to end the element in the animation with.
                            </p>
                            <p>Add the properties on the element, (start of animation, end of animation).</p>
                            <p><b>Normal properties.</b></p>
                            <Code type="js" value=" cosmicDiv.width(0, 200);
                                cosmicDiv.height(0, 200);
                                cosmicDiv.color('red', 'blue');
                                cosmicDiv.bgColor('yellow', 'green');
                                cosmicDiv.opacity(0, 1);
                                cosmicDiv.padding(0, 30);
                                cosmicDiv.margin(0, 30);
                                // Run the animation.
                                cosmicDiv.execute();"/>
                            <Console type="normalProperties"/>
                            <p><b>Properties that transform.</b></p>
                            <Code type="js" value=" cosmicDiv.rotate(0, 360);
                                cosmicDiv.translateX(300, 0);
                                cosmicDiv.translateY(300, 0);
                                cosmicDiv.scale(0, 1);
                                // Run the animation.
                                cosmicDiv.execute();"/>
                            <Console type="transformProperties"/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idMethodChaining">Method chaining</h3>
                            <p>How? Is it unpleasant to use this form?</p>
                            <p>Then let's use the method <b>chaining</b>.</p>
                            <Code type="js" value={ getCode()[6] }/>
                            <Console type="transformProperties1"/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idOtherProperties">Other properties</h3>
                            <p>If you want to use a property that is not among these, use the following method.</p>
                            <Code type="js" value={ getCode()[7] }/>
                            <p>Exit.</p>
                            <Code type="css" value={ getCode()[8] }/>
                            <p>Now run the animation.</p>
                            <Code type="js" value=" cosmicDiv.execute();"/>
                            <p>This injects a tag (style) into the DOM with the animations.</p>
                        </article>
                        <article className="code pt-0">
                            <h2 id="idFractions">Animation by fractions</h2>
                            <p>CosmicAnimation allows you to animate at different points.</p>
                            <ul>
                                <li>Animation at a specific point: (start) to (end).</li>
                                <li>Animation in fractions: (3/3), (5/5) and (9/9).</li>
                            </ul>
                            <p>This has to be specified explicitly.</p>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idAnimations3of3">Create animations (3/3)</h3>
                            <p>We can define each point of the animation in 3 steps, (3/3).</p>
                            <p>The output is the following.</p>
                            <Code type="css" value={ getCode()[9] }/>
                            <p><b>How do we do that?</b></p>
                            <p>Simply with methods belonging to <b>CosmicAnimation</b>.</p>
                            <p>Some settings for the animation.</p>
                            <Code type="js" value={ getCode()[10] }/>
                            <p>Animations, now in thirds, (3/3).</p>
                            <Code type="js" value={ getCode()[11] }/>
                            <p>If some stages of the animation are the same, we can <b>simplify</b> it by using <b>Arrays</b>.</p>
                            <Code type="js" value={ getCode()[12] }/>
                            <Console type="3of3"/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idAnimations5of5">Create animations (5/5)</h3>
                            <p>The animation is built as follows (5/5).</p>
                            <Code type="css" value={ getCode()[13] }/>
                            <p>Defines the state of a CSS property on this DIV element indicating it at each point of the animation.</p>
                            <Code type="js" value={ getCode()[14] }/>
                            <p>Remember that we can simplify it.</p>
                            <Code type="js" value={ getCode()[15] }/>
                            <Console type="5of5"/>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idAnimations9of9">Create animations (9/9)</h3>
                            <p>Now we are controlling nine stages of the animation, by splitting the animation into (9/9).</p>
                            <p>CSS Output.</p>
                            <Code type="css" value={ getCode()[16] }/>
                            <p>Properties with <b>CosmicAnimation</b>.</p>
                            <Code type="js" value={ getCode()[17] }/>
                            <Console type="9of9"/>
                        </article>
                        <article className="code pt-0">
                            <h2 id="idPrefabricated">Prefabricated animations</h2>
                            <p>If we have a few methods for creating animations, we have some pre-made methods that we can take advantage of.</p>
                        </article>
                        <article className="code pt-0">
                            <h3>From screen</h3>
                            <p>All of this applies to this DIV element that we are manipulating.</p>
                            <p>The parameters received are (<b>duration</b>) and (<b>delay</b>).</p>
                            <Code type="js" value={ getCode()[18] }/>
                            <Console type="all"/>
                        </article>
                        <article className="code pt-0">
                            <h2 id="idRestarting">When (ending) and (restarting) animation</h2>
                            <p>We have a few methods to finish the animation.</p>
                        </article>
                        <article className="code pt-0">
                            <h3 id="idTerminateMethod">(terminate) Method</h3>
                            <Code type="js" value={ getCode()[19] }/>
                            <h3 id="idDeleteAnimation">(deleteAnimation) Method</h3>
                            <p>You can clean up all the animations you put in the element and the DOM.</p>
                            <Code type="js" value={ getCode()[20] }/>
                            <h3 id="idMultipleAnimation">Multiple animation</h3>
                            <p>You can manipulate the element as many times as you want, clean up animations and define new animations.</p>
                            <Code type="js" value={ getCode()[21] }/>
                        </article>
                    </div>
                    <aside className="aside-index">
                        <ul></ul>
                    </aside>
                </div>
            </main>
        );
    }
}