import React, { Component } from "react";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Paginas.
import Main from "../pages/main";
import Docs from "../pages/docs";
import Contact from "../pages/contact";

console.clear();

class RouteProvider extends Component{
    render(){
        return (
            <Router>
                <Routes>
                    <Route path="/" element={<Main />}/>
                    <Route path="/docs" element={<Docs />}/>
                    <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </Router>
        );
    }
}

export default RouteProvider;