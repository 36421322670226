import React, { Component, useState } from "react";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import CosmicAnimation from "cosmic-animation";

import "../styles/css/contact.css";

class Contact extends Component{
    state = {
        sendingForm: false
    }

    focus(event){
        const span = event.target.parentElement.getElementsByTagName("span")[0];

        span.classList.add("active");
    }

    blur(event){
        const span = event.target.parentElement.getElementsByTagName("span")[0];

        if(event.target.value.length <= 0) span.classList.remove("active");
    }

    submit = (event) => {
        const error = document.getElementsByClassName("error")[0];
        const formData = new FormData(document.getElementsByTagName("form")[0]);
        let empty = false;

        console.log(this.state);

        formData.forEach(inputValue => {
            empty = (inputValue.length > 0 && !empty) ? false : true;
        });

        if(empty){
            error.classList.add("visible");
            error.textContent = "Do not leave any field empty";
            return void 0;
        }

        if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.get("email"))){
            error.classList.add("visible");
            error.textContent = "The email is invalid";
        }

        var data = {
            user: {
                names: formData.get("subject"),
                email: formData.get("email")
            },
            mail: {
                subject: "Contact from CosmicAnimation",
                body: formData.get("message")
            },
            app: {
                name: "CosmicAnimation"
            }
        };

        formData.append("data", JSON.stringify(data));

        this.setState({
            sendingForm: true
        });

        document.querySelector('button[type="submit"]').setAttribute("disabled", true);

        fetch("https://api.brandondeveloper.com/sendmail", {
            method: "POST",
            body: formData,
            mode: "cors"
        }).then(result => result.json()).then((response) => {
            if (!response.error) {
                Swal.fire(
                    "Message sent", 
                    "We will reply to you as soon as possible", 
                    "success"
                );
                document.getElementsByTagName("form")[0].reset();
                this.setState({ sendingForm: false });

                [...document.querySelectorAll("input"), document.getElementById("idMessage")].forEach(input => {
                    if(input.name !== "email_to"){
                        let parent = input.parentElement;
                        if(parent){
                            let span = parent.getElementsByTagName("span")[0];
                            if(span) span.classList.remove("active");
                        }
                    }
                });

                document.querySelector('button[type="submit"]').removeAttribute("disabled");
            }
            else {
                console.error("Server error: ", response.message);
            }
        }).catch(function (error) {
            console.error(error);
        });

        event.preventDefault();
    }

    hiddenError(){
        document.getElementsByClassName("error")[0].classList.remove("visible");
    }

    componentDidMount(){
        new CosmicAnimation("h1").fromWindowTo("right", 500).execute();
        new CosmicAnimation("form").fromWindowTo("left", 500).execute();

        document.getElementById("idEmailTo").parentElement.getElementsByTagName("span")[0].classList.add("active");
    }

    sendingForm(){
        if(this.state.sendingForm){
            return (<button disabled type="submit">Send</button>);
        }else{
            return (<button onClick={ this.submit } type="submit">Send</button>);
        }
    }

    render(){
        return (
            <main className="padding gradient-blue">
                <div>
                    <h1>Contact us</h1>
                </div>
                <form action="#" autoComplete="on" className="form">
                    <article>
                        <label for="idMessage">
                            <textarea name="message" onKeyDown={ this.hiddenError } onFocus={ this.focus } onBlur={ this.blur } id="idMessage" minLength="6" required></textarea>
                            <span>Your Message</span>
                        </label>
                        <label for="idEmailTo">
                            <input name="email_to" disabled value="contact@brandondeveloper.com" onKeyDown={ this.hiddenError } onFocus={ this.focus } onBlur={ this.blur } type="email" id="idEmailTo"/>
                            <span>To email</span>
                        </label>
                        <div className="form-control">
                            <label for="idEmail">
                                <input name="email" onKeyDown={ this.hiddenError } onFocus={ this.focus } onBlur={ this.blur } type="email" id="idEmail" required/>
                                <span>Your email</span>
                            </label>
                            <label for="idSubject">
                                <input name="subject" onKeyDown={ this.hiddenError } onFocus={ this.focus } onBlur={ this.blur } type="text" id="idSubject" required minLength="6"/>
                                <span>Your subject</span>
                            </label>
                        </div>
                        <div className="error">
                            Error
                        </div>
                        <div className="submit">
                            { this.sendingForm() }
                        </div>
                    </article>
                </form>
            </main>
        );
    }
}

export default Contact;