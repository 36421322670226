import React, { useState } from "react";

import { Link } from 'react-router-dom';

// Iconos.
import IconBurger from "../icons/burger";
import IconBurgerBack from "../icons/burger-back";

function HeaderMobile(){
    var [show, setShow] = useState(false);

    function toggleAside(){
        setShow(!show);
    }

    function getAside(){
        return (show) ? "show" : "";
    }

    return (
        <nav className="header-mobile">
            <figure onClick={ toggleAside }>
                <IconBurger/>
            </figure>
            <aside className={ getAside() }>
                <div>
                    <h3>Guide</h3>
                    <figure onClick={ toggleAside }>
                        <IconBurgerBack/>
                    </figure>
                </div>
                <ul className="links-mobile">
                    <li onClick={ toggleAside }>
                        <Link to="/">Home</Link>
                    </li>
                    <li onClick={ toggleAside }>
                        <Link to="/docs">Docs</Link>
                    </li>
                    <li onClick={ toggleAside }>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </aside>
        </nav>
    );
}

export default HeaderMobile;