import React, { useEffect } from "react";
import ReactDOM from 'react-dom/client';
import { createRoot } from "react-dom/client";

import IconCopy from "../icons/copy";

import 'prismjs/themes/prism.css';

// import 'prismjs/themes/prism-okaidia.css';
import Prism from 'prismjs';

function intersectionViewport(htmlElement, callback){
    const verify = (elements) => {
        if(elements[0].isIntersecting) callback(elements[0].target);
    }

    const observer = new IntersectionObserver(verify);

    observer.observe(htmlElement);
}

function getRandom(){
    let rand = Math.random().toString();
    return Number(rand.slice(2, rand.length));
}

export default function Code({type, value, children }){
    const id = "id-" + getRandom();
    let isObserve = false;

    useEffect(() => {
        /**
         * let root = ReactDOM.createRoot(document.getElementById(id)).render(<code></code>, () => console.log("Ha renderizado."));
         */
        intersectionViewport(document.querySelector("#" + id), element => {
            if(!isObserve){
                let root = createRoot(element);

                root.render(
                    <code className={ "language-" + type }>
                        { value }
                    </code>
                );
                
                setTimeout(() => {
                    Prism.highlightAll();
                    /**
                     * function MyFunction(){
                     *      return (<h1>Hello</h1>);
                     * }
                     * 
                     * let data = <div> <MyFunction/> </div>
                     */
                }, 10);
                isObserve = true;
            }
        });
    }, []);

    function hadleClick(event){
        navigator.clipboard.writeText(value)
            .then(() => {
                let dialog = event.target.parentElement.querySelector(".dialog");

                dialog.classList.add("visible");
                setTimeout(() => dialog.classList.remove("visible"), 3000);
            })
            .catch(error => {

            });
    }

    return (
        <div className="container-code">
            <figure onClick={hadleClick}>
                <div className="dialog">Copied text</div>
                <IconCopy/>
            </figure>
            <pre id={ id }></pre>
        </div>
    );
}