import React from "react";
import { useEffect } from "react";

import "../styles/css/console.css";

import CosmicAnimation from "cosmic-animation";

function intersectionViewport(htmlElement, callback){
    const verify = (elements) => {
        if(elements[0].isIntersecting) callback(elements[0].target);
    }

    const observer = new IntersectionObserver(verify);

    observer.observe(htmlElement);
}

export default function Console(props){
    useEffect(() => {
        intersectionViewport(
            document.getElementById("id-container-" + props.type), 
            () => {
                const div = new CosmicAnimation("#id-" + props.type);
                div.observeViewport.enabled = true;
                div.observeViewport.infinite = true;
                div.animation.duration = 700;

                if(props.type === "observeViewport"){
                    div.scale(0, 1).execute();
                }else if(props.type === "normalProperties"){
                    div.width(0, 150).height(0, 150)
                        .color('red', 'blue').bgColor('yellow', 'green')
                        .opacity(0, 1).padding(0, 30).margin(0, 30)
                        .execute();
                }else if(props.type === "transformProperties" || props.type === "transformProperties1"){
                    div.rotate(0, 360).translateX(150, 0).translateY(150, 0).scale(0, 1).execute();
                }else if(props.type === "3of3"){
                    div.opacity(0, "1/3").opacity(0.5, "2/3").opacity(1, "3/3")
                    .bgColor("blue", ["1/3", "2/3"])
                    .bgColor("red", "3/3")
                    .execute();
                }else if(props.type === "5of5"){
                    div.scale(0, '1/5')
                        .scale(0.3, ['2/5', '3/5']).scale(0.6, '4/5').scale(1, '5/5')
                        .bgColor('red', ['1/5', '5/5']).bgColor('blue', ['2/5', '3/5', '4/5'])
                        .execute();
                }else if(props.type === "9of9"){
                    div
                        .opacity(0, ['1/9', '2/9']).opacity(0.3, ['3/9', '4/9']).opacity(0.6, ['5/9', '6/9'])
                        .opacity(0.8, '7/9').opacity(1, ['8/9', '9/9'])
                        .translateX(100, ['1/9', '2/9', '3/9']).translateX(200, ['4/9', '5/9', '6/9'])
                        .translateX(300, ['7/9', '8/9', '9/9']).execute();
                }else if(props.type === "all"){
                    const div1 = new CosmicAnimation("#id1-" + props.type);
                    div1.observeViewport.enabled = true;
                    div1.observeViewport.infinite = true;
                    div1.animation.duration = 700;

                    div.fromWindowTo("left").execute();
                    div1.fromWindowTo("right").execute();
                }
            }
        );
    });

    return (
        <div className="console" id={ "id-container-" + props.type }>
            <div id={ "id-" + props.type } className="example-cube"></div>
            { (props.type === "all") ? <div id={ "id1-" + props.type } className="example-cube"></div> : "" }
        </div>
    );
}