import React, { Component } from "react";

import "../styles/css/404.css";
import { Link } from "react-router-dom";

export default class Error404 extends Component{
    render(){
        return (
            <main className="main-error">
                <h1>Page not found</h1>
                <p>Check the URL or return to the main page</p>
                <div>
                    <Link to="/">Home</Link>
                </div>
            </main>
        );
    }
}