import React, { Component } from "react";

import "../styles/css/header.css";

import HeaderMobile from "./header-mobile";

import { Link } from 'react-router-dom';

class HeaderComponent extends Component{
    componentDidMount(){
        let links = document.getElementById("idContainerHeader")
            .getElementsByTagName("a");

        for(let link of links){
            link.onclick = event => window.scroll({ top: 0, behavior: "smooth" });
        }
    }

    render(){
        return (
            <div id="idContainerHeader">
                <header className="padding header">
                    <picture className="logo-header">
                        <Link to="/">
                            <img src="./images/logo.svg" width="100" height="100" alt="Logo"/>
                        </Link>
                    </picture>
                </header>
                <header className="padding header">
                    <picture className="logo-header">
                        <Link to="/">
                            <img src="./images/logo.svg" width="100" height="100" alt="Logo"/>
                        </Link>
                        <h3>
                            <Link to="/">CosmicDev</Link>
                        </h3>
                    </picture>
                    <nav className="links">
                        <Link to="/docs">Docs</Link>
                        <Link to="/contact">Contact</Link>
                    </nav>
                    <HeaderMobile/>
                </header>
            </div>
        );
    }
}

export default HeaderComponent;